<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="612px"
  >
    <!--医生输入诊费-->
    <div class="main" v-if="title === '支付设置'">
      <p class="title">咨询费用</p>
	  
      <el-input
          placeholder="请输入诊疗费20-3000"
          prefix="￥"
          v-model="price"
          size="medium" min=20
		  max=3000
		  type="number" 
      >
	  
        <template slot="prefix">￥</template>
      </el-input>
    </div>
    <!--病人确认费用-->
    <div class="main" v-if="title === '去支付'">
      <p class="title">咨询费用</p>
      <p class="price">￥ <span>50</span></p>
      <p class="tip">该费用是本次治疗诊断费用，请及时去支付！</p>
    </div>
    <!--病人支付费用-->
    <div class="main" v-if="title === '确认支付'">
      <p class="title">支付金额</p>
      <p class="pay_text">
        <span>支付金额：{{total}}元</span>
        <span>优惠券：-{{couponNum}}元</span>
        <span class="red">实付金额：<span>{{total - couponNum}}</span>元</span>
      </p>
      <p class="title">优惠券</p>
      <div class="coupon_wrap">
        <el-scrollbar style="width:100%;height: 100%">
          <div class="coupons">
            <div class="coupon" v-for="(coupon,index) in coupons" :class="{choosed: currentIndex === index}" @click="currentIndex = index">
              <p>￥<span class="coupon_num">{{coupon.num}}</span> {{coupon.bill}}</p>
              <p>有效期至{{coupon.time}}</p>
              <p :class="{red: currentIndex !== index}">{{currentIndex === index ? '已使用' : '去使用'}}</p>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <p class="title">支付方式</p>
      <div class="payWays">
     <!--   <div class="wayItem" @click="currentWay = 'wx'">
          <el-image :src="require('./img/wx.jpg')"/>
          <i v-show="currentWay === 'wx'" class="el-icon-success"></i>
        </div>
        <div class="wayItem" @click="currentWay = 'zfb'">
          <el-image :src="require('./img/zfb.jpg')"/>
          <i v-show="currentWay === 'zfb'" class="el-icon-success"></i>
        </div>
        <div class="wayItem" @click="currentWay = 'online'">
          <el-image :src="require('./img/online.jpg')"/>
          <i v-show="currentWay === 'online'" class="el-icon-success"></i>
        </div> -->
      </div> 
    </div>
	
	
<!-- 	<img src="http://doctor.com//qrcode/16514544434483999.png"  />
	 -->
	
	
	
    <!--底部操作按钮-->
	
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSend" v-if="title === '支付设置'">发送微信支付</el-button>
      <el-button type="primary" @click="handleSendzfb" v-if="title === '支付设置'">发送支付宝支付</el-button>
      <el-button type="primary" @click="goToPay" v-if="title === '去支付'">去支付</el-button>
      <el-button type="primary" @click="handleSend" v-if="title === '确认支付'">支付</el-button>
    </span>
	
  </el-dialog>
</template>

<script>
  import axios from "axios"; 
  export default {
    name: 'ChatRoomPay',
    components: {
    },
    data() {
      return {
        dialogVisible: false,
        title: '去支付11',
        price: '',
		h_id:'',
        coupons: [
          {
            num: 10,
            time: '2021-08-31',
            bill: '预约咨询可用',
            choose: true
          },
          {
            num: 20,
            time: '2021-08-31',
            bill: '预约咨询可用',
            choose: false
          },
          {
            num: 30,
            time: '2021-08-31',
            bill: '预约咨询可用',
            choose: false
          },
          {
            num: 40,
            time: '2021-08-31',
            bill: '预约咨询可用',
            choose: false
          }
        ],
        currentIndex: 0,
        total: 50,
        currentWay: 'wx'
      }
    },
    computed :{
      couponNum() {
        return this.coupons[this.currentIndex].num
      }
    },
    methods: {
      open(hid){ 
		  this.h_id = hid
        this.title = '支付设置'
        this.dialogVisible = true
      },
	  
	  //支付宝支付
	  handleSendzfb(){
		  const params = new URLSearchParams();
		  params.append('price', this.price);
		  params.append('uid', this.h_id); 
		  params.append('did', sessionStorage.getItem('uid')); 
		  
		  axios.post(this.apiUrl+'/api/user/getZfbPayImg',params)
		  .then((response) => {  
		  	this.info = response.data;     
		  	
		  	this.$emit('sendPayImg',response.data)
		  	
		  	this.dialogVisible = false;
		  })
		  .catch(function(error) {
		    console.log(error);
		  }); 
	  },
	  //微信支付
      handleSend() {
		   
			
			const params = new URLSearchParams(); 
			params.append('price', this.price);
			  params.append('uid', this.h_id); 
			  params.append('did', sessionStorage.getItem('uid')); 
			params.append('type', 2); 
			
			axios.post(this.apiUrl+'/api/user/getWxPayImg',params)
			.then((response) => {  
				this.info = response.data;   
				//this.pic =response.data.data.pic;  
				
				this.$emit('sendPayImg',response.data)
				
				this.dialogVisible = false;
			})
			.catch(function(error) {
			  console.log(error);
			}); 
			
			 
			
      },
      goToPay(){
        this.title = '确认支付'
      },
      handleWay(e){
        console.log(e);
      }
    }
  }
</script>

<style scoped lang="scss">
  .main{
    .title{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      padding-bottom: 14px;
    }
    .el-input{
      margin-bottom: 90px;
    }
    .price{
      font-size: 18px;
      color: #333;
      padding-left: 20px;
      span{
        font-size: 40px;
      }
    }
    .tip{
      font-size: 12px;
      color: #FF1515;
      line-height: 17px;
      padding: 18px 0 59px;
    }
    .pay_text{
      color: #999;
      font-size: 18px;
      margin-bottom: 30px;
      &>span{
        margin-right: 48px;
      }
      .red{
        color: #FF2828;
        span{
          font-size: 40px;
        }
      }
    }
    .coupon_wrap{
      margin-bottom: 17px;
      width: 100%;
      height: 80px;
      .coupons{
        display: flex;
        height: 56px;
        .coupon{
          flex-shrink:0;
          width: 150px;
          height: 56px;
          margin-right: 24px;
          background-color: $green;
          font-size: 12px;
          color: #fff;
          text-align: center;
          line-height: 18px;
          padding-top: 4px;
          .coupon_num{
            font-size: 14px;
            padding-right: 10px;
          }
          .red{
            background-color: #FF2828;
            display: inline-block;
            position: relative;
            bottom: -2px;
            padding: 0 4px;
          }
        }
        .choosed{
          background-color: #A5E3E7;
        }
      }

    }
    .payWays{
      margin-bottom: 90px;
      display: flex;
      .wayItem{
        height: 55px;
        margin-right: 40px;
        position: relative;
        i{
          position: absolute;
          bottom: 10px;
          right: 0;
          font-size: 21px;
          color: #FF2828;
      }
      }
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 14px 20px;
    .el-input__prefix{
      left: 20px!important;
      line-height: 48px;
      font-size: 12px;
      color: #333;
    }
    .el-input--medium .el-input__inner{
      text-indent: 18px;
      height: 48px;
    }
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    padding-bottom: 50px;
    .el-button--primary{
      width: 190px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
  /deep/ .el-scrollbar__wrap{
    overflow-y: hidden!important;
  }
</style>
