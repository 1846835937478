import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import {getSessionUser, getToken} from "../utils/Storage";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/**
 * 个人中心的路由
 * @type {*[]}
 */
const centerRoutes = [
  {path: 'center', name: "资料修改", component: () => import('../views/children/center/Index')},
  {path: 'mall', name: "礼品商城", component: () => import('../views/children/mall/Index')},
  {path: 'service', name: "医疗服务", component: () => import('../views/children/service/Index')},
  {path: 'caseTemplate', name: "病例模板", component: () => import('../views/children/caseTemplate/Index')},
  {path: 'order', name: "预约订单", component: () => import('../views/children/order/Index')},
  {path: 'consult', name: "我的咨询", component: () => import('../views/children/consult/Index')},
  {path: 'message', name: "信息管理", component: () => import('../views/children/message/Index')},
  {path: 'gift', name: "我的礼物", component: () => import('../views/children/gift/Index')},
  {path: 'wallet', name: "我的账户", component: () => import('../views/children/wallet/Index')},
  {path: 'collect', name: "我的收藏", component: () => import('../views/children/collect/Index')},
  {path: 'myComment', name: "我的评价", component: () => import('../views/children/myComment/Index')},
  {path: 'commentTo', name: "对我的评价", component: () => import('../views/children/commentTo/Index')},
  {path: 'myUpdate', name: "我的上传", component: () => import('../views/children/myUpdate/Index')},
  {path: 'coupon', name: "爱心优惠券", component: () => import('../views/children/coupon/Index')},
  {path: 'usage', name: "网站使用", component: () => import('../views/children/usage/Index')},
  {path: 'recruitment', name: "求职招聘", component: () => import('../views/children/recruitment/Index')},
  {path: 'rank', name: "排名提前", component: () => import('../views/children/rank/Index')},
  {path: 'feedback', name: "意见反馈", component: () => import('../views/children/feedback/Index')},
  {path: 'copyright', name: "版权声明", component: () => import('../views/children/copyright/Index')},
  {path: 'article', name: "文章经验", component: () => import('../views/children/article/Index')},
]


const routes = [
  {
    path: "/login",
    name: "登录页面",
    component: Login
  },
  {
    path: "/",
    name: "网站首页",
    component: () => import("../views/index/Index"),
    redirect: '/home',
    children: [
		{
		  path: "/searcDoc",
		  name: "搜询医生2",
		    //component: () => import("../views/drugstore/Index")
			component: () => import("../views/newsInfo/searcDoc")
		},
		{
		  path: "/newsInfo",
		  name: "关于我们",
		  //component: () => import("../views/drugstore/Index")
		  component: () => import("../views/newsInfo/Index1")
		},
		{
		  path: "/newsInfo",
		  name: "资讯详情1",
		  //component: () => import("../views/drugstore/Index")
		  component: () => import("../views/newsInfo/Index1")
		},
		{
		  path: "/newsInfo",
		  name: "资讯详情2",
		  //component: () => import("../views/drugstore/Index")
		  component: () => import("../views/newsInfo/Index2")
		},
		{
		  path: "/newsInfo",
		  name: "资讯详情3",
		  //component: () => import("../views/drugstore/Index")
		  component: () => import("../views/newsInfo/Index3")
		},
		{
		  path: "/newsInfo",
		  name: "资讯详情4",
		  //component: () => import("../views/drugstore/Index")
		  component: () => import("../views/newsInfo/Index4")
		},
		
      {
        path: "/newsPayInfo",
        name: "付费新闻", 
        component: () => import("../views/newsPayInfo/Index")
      },
      {
        path: "/ej",
        name: "耳机", 
        component: () => import("../views/ej/Index")
      },
      {
        path: "/yq",
        name: "牙签", 
        component: () => import("../views/yq/Index")
      },
      {
        path: "/sj",
        name: "湿巾", 
        component: () => import("../views/sj/Index")
      },
      {
        path: "/zb",
        name: "纸杯", 
        component: () => import("../views/zb/Index")
      }, 
      {
        path: "/ys",
        name: "牙刷", 
        component: () => import("../views/ys/Index")
      },
	  
	  
      {
        path: "/yj",
        name: "浴巾",
        //component: () => import("../views/drugstore/Index")
        component: () => import("../views/yj/Index")
      }, 
      {
        path: "/tx",
        name: "拖鞋", 
        component: () => import("../views/tx/Index")
      },
	  
	  
      {
        path: "/yg",
        name: "牙膏",
        //component: () => import("../views/drugstore/Index")
        component: () => import("../views/yg/Index")
      },
	  
	  
      {
        path: "/home",
        name: "首页",
        component: () => import("../views/home/Index")
      },
      {
        path: "/mine",
        name: "我的卫命",
        redirect: '/mine/center',
        component: () => import("../views/mine/Index"),
        children: centerRoutes,
      },
      {
        path: "/inbox",
        name: "收件箱",
        component: () => import("../views/inbox/Index")
      },
      {
        path: "/profile",
        name: "个人中心",
        component: () => import("../views/profile/Index")
      },
      {
        path: "/search",
        name: "搜询医生",
        component: () => import("../views/search/Index")
      },
      {
        path: "/goods",
        name: "商贸中心",
        //component: () => import("../views/drugstore/Index")
        component: () => import("../views/goods/Index")
      },
      {
        path: "/goodsInfo",
        name: "商贸详情",
        //component: () => import("../views/drugstore/Index")
        component: () => import("../views/goodsInfo/Index")
      },
      {
        path: "/newsInfo",
        name: "资讯详情",
        //component: () => import("../views/drugstore/Index")
        component: () => import("../views/newsInfo/Index")
      },
      {
        path: "/news",
        name: "资讯",
        //component: () => import("../views/instruments/Index")
        component: () => import("../views/news/Index")
      },
      {
        path: "/study",
        name: "学习科普",
        component: () => import("../views/study/Index"),
        children: [
          {path: 'list', name: "教材列表", component: () => import('../views/study/List1')},

          {path: 'list', name: "医学教材、书籍", component: () => import('../views/study/List1?a=1')},
          {path: 'list', name: "医学图片图谱", component: () => import('../views/study/List1?a=2')},
          {path: 'list', name: "医学技术操作、实验科研操作视频", component: () => import('../views/study/List1?a=3')},
          {path: 'list', name: "见习实习视频", component: () => import('../views/study/List1?a=4')},
          {path: 'list', name: "教学视频", component: () => import('../views/study/List1?a=5')},
          {path: 'list', name: "手术视频", component: () => import('../views/study/List1?a=6')},
          {path: 'list', name: "科普视频", component: () => import('../views/study/List1?a=7')},
		  
		  
		  
         // {path: 'video', name: '教材视频', component: () => import("../views/study/Video")},
        ]
      },
      {path: 'book', name: '教材书籍', component: () => import("../views/study/Book")},

      {
        path: "/recruitment",
        name: "招聘求职",
        component: () => import("../views/cooperation/Index")
        // component: () => import("../views/recruitment/Index")
      },
      {
        path: "/cooperation",
        name: "海外合作",
        component: () => import("../views/cooperation/Index")
      },
      {
        path: "/contact",
        name: "联系客服",
        component: () => import("../views/contact/Index")
      },
      {
        path: "/job",
        name: "招聘详情",
        component: () => import("../views/job/Index")
      },
      {
        path: "/seeker",
        name: "求职者信息",
        component: () => import("../views/seeker/Index")
      },
      {
        path: "/doctor/:id",
        name: "医生信息",
        component: () => import("../views/search/Doctor")
      },
      {
        path: "/chat",
        name: "在线聊天",
        component: () => import("../views/chat/Index")
      },
      {
        path: "/live",
        name: "视频通话",
        component: () => import("../views/chat/Live")
      }
    ]
  },
  {
    path: "/register",
    name: "注册账号",
    component: () => import('../views/register/Register')
  },
  {path: "/", redirect: '/register'}
]


const router = new VueRouter({
  routes
})

/**
 * 约束访问权限
 * @type {string[]}
 */
// const filterRouters = ["我的卫命", "收件箱", "个人中心", "资料修改", "招聘求职"]
const filterRouters = []

router.beforeEach((to, from, next) => {
  let sessionUser = getSessionUser();
  console.log(to);
  if (getToken() && sessionUser && sessionUser.status !== 1 && filterRouters.indexOf(to.name) > 0) {
    router.replace({"name": "注册账号"})
  } else {
    next()
  }
})

export default router
