<template>
	<el-container class="center">
		<el-aside width="50px">
			<div class="title">
				个人资料
			</div>

			<div class="title">
				病历资料
			</div>

		</el-aside>
		<el-main>
			<h3 class="title">
				个人中心
				<small>病情资料中心</small>
			</h3>
			<el-tabs type="border-card" tab-position="left">



				<el-tab-pane label="我的头像">
					<el-row>
						<el-col :span="12">
							<h3>我的头像</h3>
							<!--        <el-image :src="headImg"></el-image> -->
							
							

							<el-upload class="avatar-uploader" 
								action="#" 
				:before-upload="beforeAvatarUpload"
								:show-file-list="false" 
								accept="image/*"
								:http-request="upload">
								
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							
							
							
							

						</el-col>
						
						<el-col :span="12">
							<div class="button">
								<el-upload class="avatar-uploader"
									action="#" 
									:show-file-list="false" 
									accept="image/*"
									:http-request="upload">
									
									
								<el-button type="primary"  >更换头像</el-button>
								<el-button type="primary" :http-request="upload" >上传头像</el-button>
								
								</el-upload>
								<span style="font-size: 11px; color:#606266" >头像大小不超过10M</span>
							</div>
						</el-col>
						
						
					</el-row>
					<el-row style="margin-top: 100px">
						<el-col :span="12">
							<h3>我的照片</h3>
							<el-form style="padding: 0 0 0 40px;" size="mini">
								<el-radio-group v-model="showTypeImg">
									<el-form-item>
										<el-radio label="1">所有人可见（会员和访客都可以看到照片）</el-radio>
									</el-form-item>
									<el-form-item>
										<el-radio label="2">会员可见（注册成为会员的人可以看到照片）</el-radio>
									</el-form-item>
									<el-form-item>
										<el-radio label="3">只有付费后咨询的会员可见（网站管理人员全程可见）</el-radio>
									</el-form-item>
								</el-radio-group>
							</el-form>
						</el-col>
						<el-col :span="12">
							<span></span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" class="el-col-offset-12">
							<div class="button" style="margin-top: 0">
								<el-button type="primary" @click="onSubmit1">保存</el-button>
							</div>
						</el-col>
					</el-row>

				</el-tab-pane>
				<el-tab-pane label="基本资料">
					<h3>基本资料</h3>
					<el-form ref="form" :model="form" label-width="120px" label-suffix="："
						style="padding: 0px 100px 0 0px;">
						<el-row>
							<el-col :span="12">
								<el-form-item label="昵称">
									<el-input v-model="form.nickName"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="真实姓名">
									<el-input v-model="form.userName"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>

							<el-col :span="12">
								<el-form-item label="民族">
									<el-select v-model="form.race">
										<el-option v-for="item in races_list" :key="k" :value="item.value"
											:label="item.name"></el-option>

									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="国籍">
									<el-select v-model="form.nationality">
										<el-option v-for="item in nationalities_list" :key="item.value"
											:value="item.value" :label="item.name"></el-option>
									</el-select>
								</el-form-item>
							</el-col>


						</el-row>

						<el-row>


							<el-col :span="12">
								<el-form-item label="性别">
									<el-select v-model="form.sex">
										<el-option :value="item.value" :label="item.name" v-for="item in sexs"
											:key="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="年龄">
									<el-input v-model="form.age"></el-input>
								</el-form-item>
							</el-col>
						</el-row>

						<el-row>
							<el-col :span="12">

								<el-form-item label="学历">
									<el-select v-model="form.education">
										<el-option v-for="item in educations_list" :key="item.value" :value="item.value"
											:label="item.name"></el-option>
									</el-select>
								</el-form-item>

							</el-col>
							<el-col :span="12">

								<el-form-item label="职称">
									<el-select v-model="form.doc_title">

										<el-option v-for="item in title_list" :key="item.value" :value="item.value"
											:label="item.name"></el-option>


									</el-select>
								</el-form-item>





							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">

								<el-form-item label="所属专业">
									
									<el-select v-model="form.department" >
										<el-option v-for="item in department_list"  :value="item.id"
											:label="item.name">
											
											</el-option>

									</el-select>
								</el-form-item>

							</el-col>
							<el-col :span="12">


								<el-form-item label="医生类属">
									<el-select v-model="form.dct_type" style="width: 220px;">
										<el-option :label="item.name" v-for="item in type_list" :key="k"
											:value="item.value"></el-option>
									</el-select>
								</el-form-item>



							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="身高">
									<el-input style="width:150px;" v-model="form.height"></el-input>
									<span style="color:#606266" >厘米(cm)</span>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="体重"  >	
									<el-input style="width:150px;" v-model="form.weight"></el-input>
									<span style="color:#606266" >公斤(kg)</span>
								</el-form-item>
							</el-col>
						</el-row>




						<el-row>
							<!-- <el-col :span="12">
								<el-form-item label="籍贯">
									<el-select v-model="form.hometown">
										<el-option :label="item.name" :value="item.value" v-for="item in hometowns"
											:key="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col> -->
							<el-col :span="12">
								<el-form-item label="所属医院">
									<el-input v-model="form.hospital"></el-input>
								</el-form-item>
							</el-col>

						</el-row>

						<!-- <el-form-item label="家挺住址">
              <el-row class="address">
                <el-col :span="8">
                  <el-select v-model="form.homeProvince" placeholder="选择省" @change="getHomeCities">
                    <el-option :label="item.name" :value="item.value" v-for="item in homeProvinces"
                               :key="item.value"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <el-select v-model="form.homeCity" placeholder="选择市" @change="getHomeDistricts">
                    <el-option :label="item.name" :value="item.value" v-for="item in homeCities"
                               :key="item.value"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <el-select v-model="form.homeDistrict" placeholder="选择区">
                    <el-option :label="item.name" :value="item.value" v-for="item in homeDistricts"
                               :key="item.value"></el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-input v-model="form.homeAddress"></el-input>

            </el-form-item> -->
			<el-form-item label="籍贯">
			  <el-row class="address">
			    <el-col :span="8">
			      <el-select v-model="form.homeProvince" placeholder="选择省" @change="getHomeCities">
			        <el-option :label="item.name" :value="item.value" v-for="item in homeProvinces"
			                   :key="item.value"></el-option>
			      </el-select>
			    </el-col>
			    <el-col :span="8">
			      <el-select v-model="form.homeCity" placeholder="选择市" @change="getHomeDistricts">
			        <el-option :label="item.name" :value="item.value" v-for="item in homeCities"
			                   :key="item.value"></el-option>
			      </el-select>
			    </el-col>
			    <el-col :span="8">
			      <el-select v-model="form.homeDistrict" placeholder="选择区">
			        <el-option :label="item.name" :value="item.value" v-for="item in homeDistricts"
			                   :key="item.value"></el-option>
			      </el-select>
			    </el-col>
			  </el-row>
			 <!-- <el-input v-model="form.homeAddress"></el-input> -->
			
			</el-form-item> 

						<el-form-item label="常住地址">
							<el-row class="address">
								<el-col :span="8">
									<el-select v-model="form.currentProvince" placeholder="选择省"
										@change="getCurrentCities">
										<el-option :label="item.name" :value="item.value"
											v-for="item in currentProvinces" :key="item.value"></el-option>
									</el-select>
								</el-col>
								<el-col :span="8">
									<el-select v-model="form.currentCity" placeholder="选择市"
										@change="getCurrentDistricts">
										<el-option :label="item.name" :value="item.value" v-for="item in currentCities"
											:key="item.value"></el-option>
									</el-select>
								</el-col>
								<el-col :span="8">
									<el-select v-model="form.currentDistrict" placeholder="选择区">
										<el-option :label="item.name" :value="item.value"
											v-for="item in currentDistricts" :key="item.value"></el-option>
									</el-select>
								</el-col>
							</el-row>
							<el-input v-model="form.currentAddress"></el-input>

						</el-form-item>
<el-form-item label="所能治疗疾病名称(多个中文逗号隔开)">
						<el-col :span="12"> 
							<el-form ref="form" :model="form" style="padding: 0px 200px 0 0px;">
								<el-form-item>
									<el-input style="width:555px" type="textarea" v-model="form.describe" rows="20"
										placeholder="发烧,感冒" maxlength="3000" show-word-limit></el-input>
								</el-form-item>
							</el-form>
						</el-col>

						</el-form-item>
					</el-form>
					
					
					<el-row style="margin-top: 40px">
						<el-col :span="12">
							<h3>真实姓名可见</h3>
							<el-form style="padding: 0 0 0 40px;">
								<el-radio-group v-model="showType">
									<el-form-item>
										<el-radio label="1">所有人可见（会员和访客都可以看到照片）</el-radio>
									</el-form-item>
									<el-form-item>
										<el-radio label="2">会员可见（注册成为会员的人可以看到照片）</el-radio>
									</el-form-item>
									<el-form-item>
										<el-radio label="3">只有付费后咨询的会员可见（网站管理人员全程可见）</el-radio>
									</el-form-item>
								</el-radio-group>
							</el-form>
						</el-col>
						<el-col :span="12">
							<span></span>
						</el-col>
					</el-row>
					
					
					
					<el-row>
						<el-col :span="12" class="el-col-offset-12">
							<div class="button" style="margin-top: 0">
								<el-button type="primary" @click="onSubmit2">2保存</el-button>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>


				<el-tab-pane label="简要履历">
					<h3>简要履历</h3>
					<el-form ref="form" :model="form" style="padding: 0px 200px 0 0px;">
						<el-form-item>
							<el-input type="textarea" v-model="form.resume" rows="20" placeholder="请输入履历介绍"
								maxlength="500" show-word-limit></el-input>
						</el-form-item>
					</el-form>
					<el-form style="padding: 0 0 0 40px;">
						<el-row>
							<el-col :span="12" class="el-col-offset-12">
								<div class="button" style="margin-top: 0">
									<el-button type="primary" @click="onSubmit3">保存</el-button>
								</div>
							</el-col>
						</el-row>
					</el-form>
				</el-tab-pane>


				<el-tab-pane label="业务技术简介">
					<h3>业务技术简介</h3>
					<el-form ref="form" :model="form" style="padding: 0px 200px 0 0px;">
						<el-form-item>
							<el-input type="textarea" v-model="form.memo" rows="20" placeholder="业务技术简介" maxlength="500"
								show-word-limit></el-input>
						</el-form-item>
					</el-form>
					<el-form style="padding: 0 0 0 40px;">
						<el-row>
							<el-col :span="12" class="el-col-offset-12">
								<div class="button" style="margin-top: 0">
									<el-button type="primary" @click="onSubmit4">保存</el-button>
								</div>
							</el-col>
						</el-row>
					</el-form>

				</el-tab-pane>


				<el-tab-pane label=" ">


				</el-tab-pane>
				<el-tab-pane label=" ">


				</el-tab-pane>

				<el-tab-pane ref="import" label="文字资料(模板)">
					<h3 style="float:left;	">文字资料</h3>
					<p style="float:left; margin-left: 15px;margin-top: 17px;	">最多可建40个模板</p>
					<div style="clear:both"></div>


					<el-form style="margin: 0!important;padding: 0!important;  background-color:  ;;">


						<el-form-item>
							<ul style="padding: 0!important;">

								<!-- <li  @click="addPatient(1)"  @mouseup="addPatient2()" > -->
								<li v-for="(item, i) in caseList" :key="key" @contextmenu.prevent="addPatient2(item.id)"
									@dblclick="addPatient(item.id)">
									<img src="../../../assets/images/q.png">
									<p>( {{item.name}} )</p>
								</li>

								<li @click="addCaseTemp(1)">
									<img style="width: 70px;" src="../../../assets/images/qadd.png">
								</li>

							</ul>


						</el-form-item>


					</el-form>
					<!-- 		  <el-form style="padding: 0 0 0 40px;">
		    <el-row>
		      <el-col :span="12" class="el-col-offset-12">
		        <div class="button" style="margin-top: 0">
		          <el-button type="primary" @click="onSubmit4" >保存</el-button>
		        </div>
		      </el-col>
		    </el-row>
		  </el-form> -->

				</el-tab-pane>



				<el-tab-pane label="图片资料(模板)">
					<h3 style="float:left;	">图片资料</h3>
					<p style="float:left; margin-left: 15px;margin-top: 17px;	">1最多可建40个模板</p>
					<div style="clear:both"></div>
					<el-form ref="form" :model="form"
						style="margin: 0!important;padding: 0!important;  background-color:  ;;">
						<el-form-item>
							<ul class="imgs" style="padding: 0!important;">


								<li v-for="(item, i) in caseImgList" :key="key"
									@contextmenu.prevent="addImgPatient2(item.id)" @dblclick="addImgPatient(item.id)">
									<img src="../../../assets/images/bimg.png">
									<p>( {{item.name}} )</p>
								</li>




								<li @click="addCaseImgTemp(1)">
									<img style="width: 70px;" src="../../../assets/images/qadd.png"> 
								</li>


							</ul>



						</el-form-item>
					</el-form>


				</el-tab-pane>



			</el-tabs>
		</el-main>



		<WorldCaseInfo ref="WorldCaseInfo" :info="info" />
		<WorldNameCaseInfo ref="WorldNameCaseInfo" @fatherMethod="getInfo" :info="info" />
		<ImgCaseInfo ref="ImgCaseInfo" :info="info" />
		<ImgNameCaseInfo ref="ImgNameCaseInfo" @fatherMethod="getInfo" :info="info" />



	</el-container>
</template>

<script>
	import WorldCaseInfo from '@/components/AddWorldCaseInfo';
	import ImgCaseInfo from '@/components/AddImgCaseInfo';
	import WorldNameCaseInfo from '@/components/AddWorldNameCaseInfo';
	import ImgNameCaseInfo from '@/components/AddImgNameCaseInfo';


	import axios from "axios";
	import {
		getUserinfo
	} from "../../../api/CenterApi";
	import {
		getCities,
		getDistricts,
		getNationality,
		getProvinces,
		getRaceList,
	} from "../../../api/CommonApi";

	export default {
		name: 'CenterIndex',
		components: {
			WorldCaseInfo,
			WorldNameCaseInfo,
			ImgCaseInfo,
			ImgNameCaseInfo
		},


		data() {
			return {

				//科室
				department_list: [],
				//国际
				nationalities_list: this.nationalities,
				//民族
				races_list: this.races,

				//学历
				educations_list: this.educations,

				type_list: this.type_list,
				title_list: this.title_list, //职称
				status: 0,
				caseList: {},
				caseImgList: {},


				uid: sessionStorage.getItem("uid"),
				imageUrl: require('../../../assets/images/doctor.png'),
				headImg: require('../../../assets/images/doctor.png'),
				showType: '1',
				showTypeImg: '2',
				form: {
					hometown: '',
					nickName: '',
					doc_title: '',
					race: '',
					//education:2


				},
				sexs: this.sex,
				//nationalities: [{value:1,name:'中国'}],
				//races: [{value: 1, name: '汉族'}],
				hometowns: [],
				homeProvinces: [],
				homeCities: [],
				homeDistricts: [],
				currentProvinces: [],
				currentCities: [],
				currentDistricts: [],

			}
		},
		mounted() {
			getNationality().then(res => {
				this.nationalities = res;
			})

			getRaceList().then(res => {
				this.races = res;
			})

			getProvinces().then(res => {
				this.hometowns = res;
				this.homeProvinces = res;
				this.currentProvinces = res;
			})

			this.init();
		},
		created() {
			 
			if(!sessionStorage.getItem('uid')){
				 this.$router.replace('/login')
			}


			this.getInfo()
			const formData = new FormData()
			//这里是把上传接口所有的参数都append到formData中
			formData.append('type', 1)
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			axios.post(this.apiUrl + '/api/tool/provs', formData, config).then((res) => {

				this.hometowns = this.homeProvinces = this.currentProvinces = res.data.data;

			})


			this.$refs.import.$el.click();

		},

		methods: {

			//双击打开右键图片窗口
			addImgPatient(id) {   
				this.$refs.ImgCaseInfo.open(id)
			},

			//打开右键图片窗口
			addImgPatient2(id) {
				this.$refs.ImgNameCaseInfo.open(id)
			},



			//添加图片模板
			addCaseImgTemp() {
				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));


				axios.post(this.apiUrl + '/api/user/addCaseImgTemp', params)
					.then((response) => {
						this.getInfo()
						//this.$message.success('操作成功!');  
					})


					.catch(function(error) {
						console.log(error);
					});
			},

			//添加文字模板
			addCaseTemp() {
				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));


				axios.post(this.apiUrl + '/api/user/addCaseTemp', params)
					.then((response) => {
 
						this.getInfo()

					})


					.catch(function(error) {
						console.log(error);
					});
			},

			addPatient2(id) {
				this.$refs.WorldNameCaseInfo.open(id)
			},

			addPatient(id) {
				this.$refs.WorldCaseInfo.open(id)
			},

			onSubmit4() {
				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('memo', this.form.memo);


				axios.post(this.apiUrl + '/api/user/addMemo', params)
					.then((response) => {

						this.$message.success('操作成功!');


					})


					.catch(function(error) {
						console.log(error);
					});



			},

			getInfo() {
				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));

				axios.post(this.apiUrl + '/api/user/getInfo', params)
					.then((response) => {

						if (response.data.data.pic != 0) {
							this.imageUrl = this.apiUrl + response.data.data.pic;
						}
						// this.form.nickName = response.data.data.nickName;
						// this.form.userName = response.data.data.userName;
						// this.form.race = response.data.data.race;
						// this.form.nationalitie = response.data.data.nationalitie; 
						// this.form.sex = response.data.data.sex;

						this.form = response.data.data;
						
						// this.form.currentProvince = this.form.homeProvince;
						// this.form.currentCity   = this.form.homeCity;
						// this.form.currentDistrict   = this.form.homeDistrict;
						// this.form.currentAddress  = this.form.homeAddress;

						this.showTypeImg = response.data.data.doc_img_show_type;
						this.showType = response.data.data.doc_name_show_type;
						this.caseList = response.data.data.caseList;
						this.caseImgList = response.data.data.caseImgList;
						this.department_list = response.data.data.departmentList;

						//this.form = response.data.data.caseList; 

						//this.form.homeProvince = response.data.data.homeProvince
						//城市  
						this.getHomeCities();
						//this.city_value        = response.data.wx_data.c_id;
						//区域
						//this.form.homeCity = response.data.data.homeCity;
						this.getHomeDistricts();
						//this.area_value        = response.data.wx_data.a_id;
						//省份
						this.getCurrentCities();
						//市区
						this.getCurrentDistricts();


					})
					.catch(function(error) {
						console.log(error);
					});
			},

			onSubmit3() {

				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('resume', this.form.resume);


				axios.post(this.apiUrl + '/api/user/addResume', params)
					.then((response) => {

						this.$message.success('操作成功!');

					})


					.catch(function(error) {
						console.log(error);
					});



			},
			onSubmit2() {
				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));
				// params.append('nickName', this.form.nickName);
				// params.append('userName', this.form.userName);
				// params.append('race', this.form.race);
				// params.append('nationality', this.form.nationality);
				// params.append('height', this.form.height);
				// params.append('weight', this.form.weight);
				// params.append('hometown', this.form.hometown); 

				params.append('nickName', this.form.nickName);
				params.append('userName', this.form.userName);
				// params.append('birthday', this.form.birthday);

				params.append('sex', this.form.sex);
				params.append('age', this.form.age);
				params.append('race', this.form.race);
				params.append('nationality', this.form.nationality);
				params.append('height', this.form.height);
				params.append('weight', this.form.weight);
				// params.append('marriage', this.form.marriage);
				params.append('hometown', this.form.hometown);
				params.append('describe', this.form.describe);
				params.append('doc_title', this.form.doc_title);
				params.append('education', this.form.education);


				params.append('homeProvince', this.form.homeProvince);
				params.append('homeCity', this.form.homeCity);
				params.append('homeDistrict', this.form.homeDistrict);
				params.append('homeAddress', this.form.homeAddress);
				params.append('home_address_info', this.form.homeAddress);
				params.append('currentProvince', this.form.currentProvince);
				params.append('currentCity', this.form.currentCity);
				params.append('currentDistrict', this.form.currentDistrict);
				params.append('currentAddress', this.form.currentAddress);
				params.append('doc_name_show_type', this.showType);
				params.append('hospital', this.form.hospital);
				params.append('department', this.form.department);


				axios.post(this.apiUrl + '/api/user/add', params)
					.then((response) => {

						this.$message.success('操作成功!');


					})


					.catch(function(error) {
						console.log(error);
					});



			},
			onSubmit1() {

				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('doc_img_show_type', this.showTypeImg);


				axios.post(this.apiUrl + '/api/user/add', params)
					.then((response) => {
						this.$message.success('操作成功!');
					})


					.catch(function(error) {
						console.log(error);
					});



			},
			
			
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			
			
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 10;
 
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},

			upload(e) {
				//这是参数配置，可以忽略
				const tthash = window.btoa(
					window
					.btoa(encodeURI(location.hash.replace('#/', '') ?? ''))
					.split('')
					.reverse()
					.join('')
				)
				//使用FormData 的最大优点就是可以异步上传二进制文件。
				//而图片，文件之类的文件是二进制，接口接收参数也是接收的二进制
				//可以先通过new关键字创建一个空的 FormData 对象，然后使用 append() 方法向该对象里添加字段
				//（字段的值可以是一个 Blob 对象，File对象或者字符串，剩下其他类型的值都会被自动转换成字符串）。

				//也可以用一个已有的form元素来初始化FormData对象

				this.getBase64(e.file).then((res) => {

					const formData = new FormData()

					//这里是把上传接口所有的参数都append到formData中
					formData.append('img', res)
					formData.append('uid', sessionStorage.getItem('uid'))

					const config = {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}

					//接口调用
					axios.post(this.apiUrl + '/api/user/upImg', formData, config).then((res) => {
						this.imageUrl = this.apiUrl + res.data.data.pic;
						//this.from.logo = res.data.data.filename
					})


				})



			},


			getBase64(file) {
				return new Promise(function(resolve, reject) {
					const reader = new FileReader()
					let imgResult = ''
					reader.readAsDataURL(file)
					reader.onload = function() {
						imgResult = reader.result
					}
					reader.onerror = function(error) {
						reject(error)
					}
					reader.onloadend = function() {
						resolve(imgResult)
					}
				})
			},
			init() {
				getUserinfo().then(res => {
					this.form = res;
				})
			},

			getHomeCities(val) {

				// let obj = {}
				//         obj = this.homeProvinces.find(item => {
				//           //这里的operateOption就是上面遍历的数据源
				//           return item.id == 2 //筛选出匹配数据
				//         }) 

				//         console.log('修改操作人名称', obj.label) 

				const formData = new FormData()
				//这里是把上传接口所有的参数都append到formData中
				formData.append('id', this.form.homeProvince)
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
				axios.post(this.apiUrl + '/api/tool/citys', formData, config).then((res) => {
					this.homeCities = res.data.data;
				})

				// this.form.homeCity = '';
				// this.form.homeDistrict = '';
				// getCities(this.form.homeProvince).then(res => {
				//   this.homeCities = res;
				// })
			},

			getHomeDistricts() {

				const formData = new FormData()
				//这里是把上传接口所有的参数都append到formData中
				formData.append('id', this.form.homeCity)
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
				axios.post(this.apiUrl + '/api/tool/areas', formData, config).then((res) => {
					this.homeDistricts = res.data.data;
				})

				// this.form.homeDistrict = '';
				// getDistricts(this.form.homeCity).then(res => {
				//   this.homeDistricts = res;
				// })
			},

			getCurrentCities() {
				const formData = new FormData()
				//这里是把上传接口所有的参数都append到formData中
				formData.append('id', this.form.currentProvince)
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
				axios.post(this.apiUrl + '/api/tool/citys', formData, config).then((res) => {
					this.currentCities = res.data.data;
				})
				// this.form.currentCity = '';
				// this.form.currentDistrict = '';
				// getCities(this.form.currentProvince).then(res => {
				//   this.currentCities = res;
				// })
			},

			getCurrentDistricts() {
				const formData = new FormData()
				//这里是把上传接口所有的参数都append到formData中
				formData.append('id', this.form.currentCity)
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
				axios.post(this.apiUrl + '/api/tool/areas', formData, config).then((res) => {
					this.currentDistricts = res.data.data;
				})
				// this.form.currentDistrict = '';
				// getDistricts(this.form.currentCity).then(res => {
				//   this.currentDistricts = res;
				// })
			}
		}
	}
</script>

<style scoped lang="scss">
	
	.el-select-dropdown__item{
		width:100%;
		height:100%;
		
	}
	.imgs li img {
		width: 60px;
	}

	li {
		list-style-type: none;
		width: 20%;
		height: 20%;
		//background-color: rosybrown;
		float: left;
		text-align: center;
	}

	li img {
		width: 35px;
	}

	#tab-4 {
		margin-top: 300px !important;
	}

	.el-upload--text {

		border: 1px dashed #d9d9d9;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
		border: 1px dashed #d9d9d9;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.center {
		width: 100%;
		min-height: 1050px;
		background-color: #ffffff;
		box-shadow: white;

		.el-aside {
			.title {
				height: 100%;
				-webkit-writing-mode: vertical-lr;
				writing-mode: vertical-lr;
				text-align: center;
				display: block;
				margin: 14px;
				font-size: 18px;
				font-weight: bold;
				letter-spacing: 10px
			}
		}

		.el-main {
			.title {

				small {
					margin-left: 10px;
					font-weight: normal;
				}
			}

			.el-tabs {
				height: calc(100% - 60px);

				/deep/ .el-tabs__header {
					width: 150px;
				}

				/deep/ .el-tabs__item {
					margin: 50px 0 50px -1px;
					color: #000 !important;
					text-align: center;

					&.is-active {
						border-left: 4px solid $green;
					}
				}

				.el-image {
					border: 1px solid #979797;
					margin-top: 20px;
					width: 150px;
					height: 150px;
				}

				.button {
					margin-top: 150px;
					padding-left: 50px;

					.el-button {
						display: block;
						margin: 10px 0;
						width: 120px;
					}
				}

				.address {
					&+.el-input {
						margin-top: 10px;
					}

					.el-col {
						padding: 0 5px 0 0;

						&:last-child {
							padding: 0;
						}
					}
				}

				/deep/ .el-radio__label {
					font-size: 12px;
				}
			}
		}


	}

	.el-select {
		width: 100%;
	}
</style>
