<template>
  <el-card>
    <div slot="header">
      <h3>我的信息管理</h3>
    </div>

    <div class="body">
      <el-tabs v-model="tabActive">
        <el-tab-pane label="在线或门诊咨询记录（门诊病历）" name="first"></el-tab-pane>
        <!-- <el-tab-pane label="住院记录（病历）" name="second"></el-tab-pane> -->
      </el-tabs>

      <el-form :inline="true" :model="query" class="text-center">
        <el-form-item>
          <el-date-picker
              v-model="query.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.id" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>


      <el-row :gutter="10" class="content">
		  
        <el-col      :span="3" class="text-center margin-bottom" v-for="v in list"     >
          <el-image @click="goInfo(1)" :src="url"></el-image>
          <p    @click="goInfo(1)" >{{v.id}} </p> 
        </el-col>
		
      </el-row> 

      <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination> -->
    </div>
	<ChatRoomAddCaseRecordPatient ref="ChatRoomAddCaseRecordPatient" />
		<ChatRoomAddCaseRecord ref="ChatRoomAddCaseRecord"  />
  </el-card>
</template>
 

<script>
	
	
  import axios from "axios"; 	
  
  import ChatRoomAddCaseRecord from '@/components/ChatRoomAddCaseRecord'
  
  import caseRecord from '@/components/AddODCaseInfo'
  
  import ChatRoomAddCaseRecordPatient from '@/components/ChatRoomAddCaseRecordPatient'
  
  
  export default {
	  
    name: 'MessageIndex',
	
	components: { ChatRoomAddCaseRecordPatient,ChatRoomAddCaseRecord, }, 
	
    data() {
      return { 
		  list:[],
        tabActive: "first",
        query: {},
        url: require("../../../assets/images/m_case.png")
      }
    },
	created() {   
	  //this.enterSearch();  
	  
	  const params = new URLSearchParams();
	  params.append('uid', sessionStorage.getItem('uid')); 
	  	  	  
	  	  	 
	 // params.append('content', this.textarea);  
	  
	  axios.post(this.apiUrl+'/api/user/getChatRoomAddCaseRecordHuaiList', params)
	  .then((response) => { 	
	  		// this.$message.success('操作成功');
			
			this.list	 =  response.data.data;
			 
	    })
	    .catch(function(error) {
	      console.log(error);
	    }); 
	}
  
  
  ,
   methods: { 
	   goInfo(id){
		 
				//this.$refs.ChatRoomAddCaseRecordPatient.open(id);
			 this.$refs.ChatRoomAddCaseRecord.open(1, 1); 
	   }
   }
  }
</script>

<style scoped lang="scss">
  .el-card {
    min-height: 1050px;

    /deep/ .el-card__header {

      h3 {
        padding: 0px;
        margin: 0px;
      }
    }

    .body {
      .content {
        padding: 20px;

        .margin-bottom{
          margin-bottom: 20px;
        }


      }

      /deep/ .el-pagination {
        text-align: center;
      }

    }

  }
</style>
