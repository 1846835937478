import { render, staticRenderFns } from "./List1.vue?vue&type=template&id=098e7e14&scoped=true&a=6"
import script from "./List1.vue?vue&type=script&lang=js&a=6"
export * from "./List1.vue?vue&type=script&lang=js&a=6"
import style0 from "./List1.vue?vue&type=style&index=0&id=098e7e14&scoped=true&lang=scss&a=6"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098e7e14",
  null
  
)

export default component.exports