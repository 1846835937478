import { render, staticRenderFns } from "./List1.vue?vue&type=template&id=895cb1ce&scoped=true&a=3"
import script from "./List1.vue?vue&type=script&lang=js&a=3"
export * from "./List1.vue?vue&type=script&lang=js&a=3"
import style0 from "./List1.vue?vue&type=style&index=0&id=895cb1ce&scoped=true&lang=scss&a=3"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "895cb1ce",
  null
  
)

export default component.exports