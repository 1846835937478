import { render, staticRenderFns } from "./List1.vue?vue&type=template&id=1085c995&scoped=true&a=7"
import script from "./List1.vue?vue&type=script&lang=js&a=7"
export * from "./List1.vue?vue&type=script&lang=js&a=7"
import style0 from "./List1.vue?vue&type=style&index=0&id=1085c995&scoped=true&lang=scss&a=7"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1085c995",
  null
  
)

export default component.exports