<template>
  <el-card>
    <div slot="header">
		
		<MyUpdata ref="MyUpdata" :info="info"/>
      <h3>我的上传</h3>
    </div>
	
	
	
    <el-tabs v-model="tabActive"  >
		
		
      <el-tab-pane label="医学教材、书籍" name="first">
		  <div class="body">
		    <el-row>
		      <el-col :span="18">
		        <el-form :inline="true" :model="query">
		          <el-form-item label="时间段查询：">
		            <el-date-picker
		                v-model="query.dateRange"
		                type="daterange"
		                range-separator="至"
		                start-placeholder="开始日期"
		                end-placeholder="结束日期">
		            </el-date-picker>
		          </el-form-item>
		          <el-form-item>
		            <el-button type="primary" icon="el-icon-search">查询</el-button>
		          </el-form-item>
		        </el-form>
		      </el-col>
		   <!--   <el-col :span="4">
		        <h4>账户余额：2800 元</h4>
		      </el-col> -->
		      <el-col :span="2">
		        <el-button class="cashout" @click="ups(1)" type="primary">上传</el-button>
		      </el-col>
		    </el-row>
		  
		  
		  <!-- <el-table show-summary :data="rows"> -->
		    <el-table   :data="rows1">
		      <el-table-column
		          prop="id"
		          label="id"
		          width="150">
		      </el-table-column>
			  
			  <el-table-column
			      prop="title"
			      label="标题" >
			  </el-table-column>
			   
		      <el-table-column
		          prop="type"
		          label="状态" >
		      </el-table-column> 
		   
		      <el-table-column
		          prop="create_time"
		          label="提交时间" >
		      </el-table-column>
			  
			  
			  <el-table-column
			      prop=" "
			      label="操作"  >
				  
			  </el-table-column> 
		  
		    </el-table>
		   
		  </div>
		  
	  </el-tab-pane>
	  
	  
	  <!-- 医学图片图谱-->
      <el-tab-pane label="医学图片图谱" name="second"  >
		  
		  <div class="body">
		  	    <el-row>
		  	      <el-col :span="18">
		  	        <el-form :inline="true" :model="query">
		  	          <el-form-item label="时间段查询：">
		  	            <el-date-picker
		  	                v-model="query.dateRange"
		  	                type="daterange"
		  	                range-separator="至"
		  	                start-placeholder="开始日期"
		  	                end-placeholder="结束日期">
		  	            </el-date-picker>
		  	          </el-form-item>
		  	          <el-form-item>
		  	            <el-button type="primary" icon="el-icon-search">查询</el-button>
		  	          </el-form-item>
		  	        </el-form>
		  	      </el-col>
		  	  
		  	      <el-col :span="2">
		  	        <el-button class="cashout" @click="ups(2)" type="primary">上传</el-button>
		  	      </el-col>
		  	    </el-row>
		  	   
		  	    <el-table   :data="rows2">
		  	      <el-table-column
		  	          prop="id"
		  	          label="id"
		  	          width="150">
		  	      </el-table-column>
		  		  
		  		  <el-table-column
		  		      prop="type"
		  		      label="标题"
		  		       >
		  		  </el-table-column>
		  		   
		  	      <el-table-column
		  	          prop="price"
		  	          label="状态"
		  	           >
		  	      </el-table-column> 
		  	   
		  	      <el-table-column
		  	          prop="create_time"
		  	          label="提交时间"
		  	           >
		  	      </el-table-column> 
		  		  
		  		  <el-table-column
		  		      prop="create_time"
		  		      label="操作"
		  		       
		  			  >
		  		  </el-table-column> 
		  	  
		  	    </el-table> 
		  	  </div>
	  </el-tab-pane>
	  <!--医学图片图谱  -->
	  
	  
	  
	  
	  
      <el-tab-pane label=" 医学技术操作、实验科研操作视频 " name="men3">
		  <div class="body">
		  	    <el-row>
		  	      <el-col :span="18">
		  	        <el-form :inline="true" :model="query">
		  	          <el-form-item label="时间段查询：">
		  	            <el-date-picker
		  	                v-model="query.dateRange"
		  	                type="daterange"
		  	                range-separator="至"
		  	                start-placeholder="开始日期"
		  	                end-placeholder="结束日期">
		  	            </el-date-picker>
		  	          </el-form-item>
		  	          <el-form-item>
		  	            <el-button type="primary" icon="el-icon-search">查询</el-button>
		  	          </el-form-item>
		  	        </el-form>
		  	      </el-col>
		  	  
		  	      <el-col :span="2">
		  	        <el-button class="cashout" @click="ups(3)" type="primary">上传</el-button>
		  	      </el-col>
		  	    </el-row>
		  	   
		  	    <el-table   :data="rows3">
		  	      <el-table-column
		  	          prop="id"
		  	          label="id"
		  	          width="150">
		  	      </el-table-column>
		  		  
		  		  <el-table-column
		  		      prop="type"
		  		      label="标题"
		  		       >
		  		  </el-table-column>
		  		   
		  	      <el-table-column
		  	          prop="price"
		  	          label="状态"
		  	           >
		  	      </el-table-column> 
		  	   
		  	      <el-table-column
		  	          prop="create_time"
		  	          label="提交时间"
		  	           >
		  	      </el-table-column> 
		  		  
		  		  <el-table-column
		  		      prop="create_time"
		  		      label="操作"
		  		       
		  			  >
		  		  </el-table-column> 
		  	  
		  	    </el-table> 
		  	  </div>
	  </el-tab-pane>
      <el-tab-pane label=" 见习实习视频 " name="men4">
		  
		  <div class="body">
		  	    <el-row>
		  	      <el-col :span="18">
		  	        <el-form :inline="true" :model="query">
		  	          <el-form-item label="时间段查询：">
		  	            <el-date-picker
		  	                v-model="query.dateRange"
		  	                type="daterange"
		  	                range-separator="至"
		  	                start-placeholder="开始日期"
		  	                end-placeholder="结束日期">
		  	            </el-date-picker>
		  	          </el-form-item>
		  	          <el-form-item>
		  	            <el-button type="primary" icon="el-icon-search">查询</el-button>
		  	          </el-form-item>
		  	        </el-form>
		  	      </el-col>
		  	  
		  	      <el-col :span="2">
		  	        <el-button class="cashout" @click="ups(4)" type="primary">上传</el-button>
		  	      </el-col>
		  	    </el-row>
		  	   
		  	    <el-table   :data="rows4">
		  	      <el-table-column
		  	          prop="id"
		  	          label="id"
		  	          width="150">
		  	      </el-table-column>
		  		  
		  		  <el-table-column
		  		      prop="type"
		  		      label="标题"
		  		       >
		  		  </el-table-column>
		  		   
		  	      <el-table-column
		  	          prop="price"
		  	          label="状态"
		  	           >
		  	      </el-table-column> 
		  	   
		  	      <el-table-column
		  	          prop="create_time"
		  	          label="提交时间"
		  	           >
		  	      </el-table-column> 
		  		  
		  		  <el-table-column
		  		      prop="create_time"
		  		      label="操作"
		  		       
		  			  >
		  		  </el-table-column> 
		  	  
		  	    </el-table> 
		  	  </div>
	  </el-tab-pane>
      <el-tab-pane label="  教学视频  " name="men5">
		  
		  <div class="body">
		  	    <el-row>
		  	      <el-col :span="18">
		  	        <el-form :inline="true" :model="query">
		  	          <el-form-item label="时间段查询：">
		  	            <el-date-picker
		  	                v-model="query.dateRange"
		  	                type="daterange"
		  	                range-separator="至"
		  	                start-placeholder="开始日期"
		  	                end-placeholder="结束日期">
		  	            </el-date-picker>
		  	          </el-form-item>
		  	          <el-form-item>
		  	            <el-button type="primary" icon="el-icon-search">查询</el-button>
		  	          </el-form-item>
		  	        </el-form>
		  	      </el-col>
		  	  
		  	      <el-col :span="2">
		  	        <el-button class="cashout" @click="ups(5)" type="primary">上传</el-button>
		  	      </el-col>
		  	    </el-row>
		  	   
		  	    <el-table   :data="rows5">
		  	      <el-table-column
		  	          prop="id"
		  	          label="id"
		  	          width="150">
		  	      </el-table-column>
		  		  
		  		  <el-table-column
		  		      prop="type"
		  		      label="标题"
		  		       >
		  		  </el-table-column>
		  		   
		  	      <el-table-column
		  	          prop="price"
		  	          label="状态"
		  	           >
		  	      </el-table-column> 
		  	   
		  	      <el-table-column
		  	          prop="create_time"
		  	          label="提交时间"
		  	           >
		  	      </el-table-column> 
		  		  
		  		  <el-table-column
		  		      prop="create_time"
		  		      label="操作"
		  		       
		  			  >
		  		  </el-table-column> 
		  	  
		  	    </el-table> 
		  	  </div>
	  </el-tab-pane>
      <el-tab-pane label="  手术视频  " name="men6">
		  
		  <div class="body">
		  	    <el-row>
		  	      <el-col :span="18">
		  	        <el-form :inline="true" :model="query">
		  	          <el-form-item label="时间段查询：">
		  	            <el-date-picker
		  	                v-model="query.dateRange"
		  	                type="daterange"
		  	                range-separator="至"
		  	                start-placeholder="开始日期"
		  	                end-placeholder="结束日期">
		  	            </el-date-picker>
		  	          </el-form-item>
		  	          <el-form-item>
		  	            <el-button type="primary" icon="el-icon-search">查询</el-button>
		  	          </el-form-item>
		  	        </el-form>
		  	      </el-col>
		  	  
		  	      <el-col :span="2">
		  	        <el-button class="cashout" @click="ups(6)" type="primary">上传</el-button>
		  	      </el-col>
		  	    </el-row>
		  	   
		  	    <el-table   :data="rows6">
		  	      <el-table-column
		  	          prop="id"
		  	          label="id"
		  	          width="150">
		  	      </el-table-column>
		  		  
		  		  <el-table-column
		  		      prop="type"
		  		      label="标题"
		  		       >
		  		  </el-table-column>
		  		   
		  	      <el-table-column
		  	          prop="price"
		  	          label="状态"
		  	           >
		  	      </el-table-column> 
		  	   
		  	      <el-table-column
		  	          prop="create_time"
		  	          label="提交时间"
		  	           >
		  	      </el-table-column> 
		  		  
		  		  <el-table-column
		  		      prop="create_time"
		  		      label="操作"
		  		       
		  			  >
		  		  </el-table-column> 
		  	  
		  	    </el-table> 
		  	  </div>
	  </el-tab-pane>
      <el-tab-pane label="  科普视频  " name="men7">
		  
		  <div class="body">
		  	    <el-row>
		  	      <el-col :span="18">
		  	        <el-form :inline="true" :model="query">
		  	          <el-form-item label="时间段查询：">
		  	            <el-date-picker
		  	                v-model="query.dateRange"
		  	                type="daterange"
		  	                range-separator="至"
		  	                start-placeholder="开始日期"
		  	                end-placeholder="结束日期">
		  	            </el-date-picker>
		  	          </el-form-item>
		  	          <el-form-item>
		  	            <el-button type="primary" icon="el-icon-search">查询</el-button>
		  	          </el-form-item>
		  	        </el-form>
		  	      </el-col>
		  	  
		  	      <el-col :span="2">
		  	        <el-button class="cashout" @click="ups(7)" type="primary">上传</el-button>
		  	      </el-col>
		  	    </el-row>
		  	   
		  	    <el-table   :data="rows7">
		  	      <el-table-column
		  	          prop="id"
		  	          label="id"
		  	          width="150">
		  	      </el-table-column>
		  		  
		  		  <el-table-column
		  		      prop="type"
		  		      label="标题"
		  		       >
		  		  </el-table-column>
		  		   
		  	      <el-table-column
		  	          prop="price"
		  	          label="状态"
		  	           >
		  	      </el-table-column> 
		  	   
		  	      <el-table-column
		  	          prop="create_time"
		  	          label="提交时间"
		  	           >
		  	      </el-table-column> 
		  		  
		  		  <el-table-column
		  		      prop="create_time"
		  		      label="操作"
		  		       
		  			  >
		  		  </el-table-column> 
		  	  
		  	    </el-table> 
		  	  </div>
			  
	  </el-tab-pane> 
	  
	  
	  
	  
    </el-tabs>



	  
	  
   
  </el-card>
   
 
  
  
</template>

<script>

  import axios from "axios"; 
  
  import MyUpdata from '../../../components/MyUpdata.vue'
  export default {
    name: "WalletIndex",
    components: {  MyUpdata },
    data() {
      return {
		  info:{},
        tabActive: "first",
        query: {},
        rows: [],
        rows1: [],
        rows2: [],
        rows3: [],
        rows4: [],
        rows5: [],
        rows6: [],
        rows7: [],
		type:1,
      }
    },watch : {
   'tabActive':function(val) { 
	   //alert(val)
	   //监听切换状态-计划单 
	   if(val == 'first'){
		  this.type = 1;
	   }
	   if(val == 'second'){
		  this.type = 2;
	   }
	   if(val == 'men3'){
		  this.type = 3;
	   }
	   if(val == 'men4'){
		  this.type = 4;
	   }
	   if(val == 'men5'){
		  this.type = 5;
	   }
	   if(val == 'men6'){
		  this.type = 6;
	   }
	   if(val == 'men7'){
		  this.type = 7;
	   }
	    const params = new URLSearchParams();
	    params.append('u_id', sessionStorage.getItem('uid')); 
	    params.append('type', this.type); 
	    console.log(params);
	    axios.post(this.apiUrl+'/api/user/getUpdateSelf',params)
	    .then((response) => {   
			
			if(this.type == 1){
	    	 this.rows1 = response.data.data; 
			}
			if(this.type == 2){
	    	 this.rows2 = response.data.data; 
			}
			if(this.type == 3){
	    	 this.rows3 = response.data.data; 
			}
			if(this.type == 4){
	    	 this.rows4 = response.data.data; 
			}
			if(this.type == 5){
	    	 this.rows5 = response.data.data; 
			}
			if(this.type == 6){
	    	 this.rows6 = response.data.data; 
			}
			if(this.type == 7){
	    	 this.rows7 = response.data.data; 
			}
			
	    }) 
	    .catch(function(error) {
	      console.log(error);
	    });
	   
        // let urlStr = '/index/test?tabid=' + val;
        // this.$router.push(urlStr);
        // 注释，根据val的不同，跳转到不同
   },
},
	created(){  
		const params = new URLSearchParams();
		params.append('u_id', sessionStorage.getItem('uid')); 
	    params.append('type', 1); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getUpdateSelf',params)
		.then((response) => {   
			 this.rows1 = response.data.data; 
		}) 
		.catch(function(error) {
		  console.log(error);
		});
	},
    methods:{
		
 
		ups(e) {
		  this.$refs.MyUpdata.open(e)
		},
		
		
		 
	}
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    .el-card__header {
      h3 {
        margin: 0;
        padding: 0;
      }
    }

    .body {
      h4 {
        margin: 0;
        padding: 10px 0;
      }

      .cashout {
        margin-top: 3px;
        width: 100%;
      }

      .el-table {
        width: 100%;
      }

      .el-pagination {
        text-align: center;
        margin-top: 20px;
      }


    }
  }
</style>
