<template>
  <!--注册账号的功能-->
  <site-layout class="register">
    <div class="wrap">
      <!-- 注册提示 -->
      <div class="step01" v-if="step === 0">
        <Protocol @next="toNext"></Protocol>
      </div>

      <!-- 账号基础信息 -->
      <div class="step02" v-if="step === 1">
        <Account @openProtocol="openProtocol" @toNext="toNext"></Account>
      </div>

      <!-- 身份基础信息 -->
      <div class="step02" v-if="step === 2">
        <Userinfo @toNext="toNext" @toLast="toLast"></Userinfo>
      </div>

      <!-- 个人学历资料 -->
      <div class="step02" v-if="step === 3">
		
        <!-- 角色主键为1的时候是病人-->
        <template v-if="roleId == 1">
          <EduPatient @toNext="toNext" @toLast="toLast"></EduPatient>
        </template>
        <!--角色主键为2的时候是医生-->
        <template v-else-if="roleId == 2">
          <EduDoctor @toNext="toNext" @toLast="toLast"></EduDoctor>
        </template>
        <!--角色主键为3的时候是企业-->
        <template v-else >
          <EduAgent @toNext="toNext" @toLast="toLast"></EduAgent>
        </template>
      </div>

      <!-- 银行卡信息 -->
      <div class="step02" v-if="step === 4">
        <ThirdParty @toNext="toNext" @toLast="toLast"></ThirdParty>
		
		
      </div>

	<div class="step02" v-if="step === 5">
        <div class="status" v-if="user.status === 0">
          <el-image :src="imgWt" ></el-image>
          <el-button size="small" @click="toHome">返回首页</el-button>
        </div>
        <div class="status" v-if="user.status === 2">
          <el-image class="error" :src="imgNo"></el-image>

          <div class="tips">
            （失败原因：{{ user.failReason }} )
          </div>

          <el-button size="small" style="margin-top: 50px;" @click="toLast(user.step - 1)">
            重新填写信息
          </el-button>
        </div>
      </div>
	  
	  
      <div class="step02" v-if="success_show">
        <div class="status" >
          <el-image :src="imgWt" ></el-image>
          <el-button size="small" @click="toHome">返回首页</el-button>
        </div> 
      </div>
	  
	  
	  
	  
    </div>
  </site-layout>

</template>

<script>
  import axios from "axios"; 
  import SiteLayout from "../../layout/SiteLayout";

  import {goBack, saveRegister} from "../../api/LoginApi";
  import {getSessionUser, setSessionUser,} from "../../utils/Storage";
  import {getProvince} from "../../data/pca";
  import Protocol from "./Protocol";
  import Account from "./Account";
  import Userinfo from "./Userinfo";
  import ThirdParty from "./ThirdParty";
  import EduPatient from "./EduPatient";
  import EduAgent from "./EduAgent";
  import EduDoctor from "./EduDoctor";

  export default {
    name: "Register",
    components: {EduDoctor, EduAgent, ThirdParty, Userinfo, Account, Protocol, SiteLayout, EduPatient},
    data() {
      return { 
		success_show:0,
		//roleId:sessionStorage.getItem('roleId'),
        step: 1,
        imgWt: require("../../assets/images/registered_wt.png"),
        imgNo: require("../../assets/images/registered_no.png")
      }
    },

    computed: {
      roleId() {  
        //return getSessionUser().roleId;
		return sessionStorage.getItem('roleId');
      },
	   
      user() {
        return getSessionUser();
      }
    },
	created() {
	
	},

    mounted() {
      let sessionUser = getSessionUser();
      if (sessionUser && sessionUser.id) {
        this.step = sessionUser.step;
      } else {
        //this.step = 1;
        this.step = 0;
      }
    },

    methods: {
      /**
       * 下一步的操作
       */
      toNext(formData) {
        console.log(formData);
        if (this.step < 5 && this.step > 0) {
		 
		 
			const params = new URLSearchParams();
			
			params.append('step', this.step); 
			//params.append('uid', sessionStorage.getItem('uid'));
			//params.append('uid', 24);
			//登录第一步
			if(this.step==1){ 
				params.append('name', formData.loginName);
				params.append('pwd', formData.loginPassword);
				params.append('tel', formData.telphone); 
				params.append('type', formData.roleId); 
				params.append('code', formData.smsVerificationCode); 
				sessionStorage.setItem('roleId',formData.roleId);  
			}
			
			if(this.step==2){
				//登录第二步
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('sex', formData.sex);
				params.append('userName', formData.userName); 
				params.append('birthday', formData.birthday);
				params.append('age', formData.age);
				params.append('race', formData.race);
				params.append('nationality', formData.nationality);
				params.append('height', formData.height);
				params.append('weight', formData.weight);
				params.append('marriage', formData.marriage);
				params.append('hometown', formData.hometown);
				
				params.append('homeProvince', formData.homeProvince);
				params.append('homeCity', formData.homeCity);
				params.append('homeDistrict', formData.homeDistrict);
				params.append('homeAddress', formData.homeAddress);
				
				params.append('currentProvince', formData.currentProvince);
				params.append('currentCity', formData.currentCity);
				params.append('currentDistrict', formData.currentDistrict);
				params.append('currentAddress', formData.currentAddress);
				
				//企业
				params.append('business_no', formData.socialCreditCode);
				params.append('business_no', formData.socialCreditCode);
				params.append('business_no', formData.socialCreditCode);
				
				this.roleId = sessionStorage.getItem('roleId');
			
			}
			
			
			if(this.step==3){
				//企业
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('education', formData.education);
				params.append('agentName', formData.agentName);
				params.append('relationship', formData.relationship);
				params.append('job', formData.job);
				params.append('agentColleges', formData.agentColleges);
				params.append('agentMajor', formData.agentMajor);
				params.append('agentProfession', formData.agentProfession);
				params.append('specialty', formData.specialty);
				params.append('businessScope', formData.businessScope);
				this.roleId = sessionStorage.getItem('roleId');
				
				//医生
				params.append('isGraduated', formData.isGraduated);
				params.append('doc_name', formData.name);
				params.append('doc_education', formData.education);
				params.append('colleges', formData.colleges);
				params.append('studyMajor', formData.studyMajor);
				params.append('graduationCert', formData.graduationCert);
				params.append('graduationDate', formData.graduationDate);
				params.append('degreeCert', formData.degreeCert);
				params.append('degreeDate', formData.degreeDate);
				params.append('profession', formData.profession);
				params.append('registeredMajor', formData.registeredMajor);
				params.append('doc_memo', formData.memo);
				params.append('qualificationCertType', formData.qualificationCertType);
				params.append('qualificationCert', formData.qualificationCert);
				params.append('qualificationMajor', formData.qualificationMajor);
				params.append('qualificationIssuer', formData.qualificationIssuer);
				params.append('qualificationDate', formData.qualificationDate);
				params.append('doc_colleges', formData.colleges);
				params.append('doc_studyMajor', formData.studyMajor);
				params.append('doc_education_reding', formData.education);
				params.append('studyDate', formData.studyDate);
				params.append('schoolSystem', formData.schoolSystem);
				params.append('studyLength', formData.studyLength);
				
				//病人
				params.append('h_education', formData.education);
				params.append('h_colleges', formData.colleges);
				params.append('h_profession', formData.profession);
				params.append('h_memo', formData.memo); 
				
			}
			
			if(this.step==4){
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('bankName', formData.bankName);
				params.append('bankCard', formData.bankCard);
				params.append('alipay', formData.alipay);
				params.append('wechat', formData.wechat);
				params.append('email', formData.email);
				params.append('qq', formData.qq);  
				this.roleId = sessionStorage.getItem('roleId');
				
				this.success_show = 1;
	 
				
			} 
			
			axios.post(this.apiUrl+'/api/login/register', params)
			.then((response) => { 
			 
				// alert(response.data.code);
				if(response.data.code == 3){
								  
					this.$message.alert('验证码错误！') 
				}else{
					 
				// alert(response.data.data.end);
				// if(response.data.data.end ){
				// 	this.$message.success('操作成功！');
				// 	 this.$router.replace('/home')
				// }
						  //alert(response.data.data.uname);
						  //病人情况下 注册后  直接进入登录状态
						  
							    sessionStorage.setItem("uid", response.data.data.uid);
							    sessionStorage.setItem("uname", response.data.data.uname);
								
								
				}
							  
							  		//this.step++;	  
							  
							// this.$alert('操作成功!', this.alert_mes, {
							// 	confirmButtonText: '确定',
							// 	callback: action => {
							// 	  this.$router.replace('/home')
							// 	}
							// });
						  
						  
			  })
			
			
			  .catch(function(error) {
			    console.log(error);
			  }); 	
			//开始注册
          // saveRegister(formData).then((data) => {
          //   this.step++;
          //   if (data) {
          //     data.step = this.step;
          //     setSessionUser(data)
          //   } else {
          //     let sessionUser = getSessionUser();
          //     sessionUser.step = this.step;
          //     setSessionUser(sessionUser)
          //   }
          // })
		  
		  this.step++; 
        } else {
          this.step++;
        }
		//this.step++;
      },

      /**
       * 上一步的操作
       */
      toLast(preStep) {
        if (preStep > 0) {
			 this.step--;
          // let sessionUser = getSessionUser();
          // goBack({accountId: sessionUser.id}).then(res => {
          //   this.step--;
          //   sessionUser.step = this.step;
          //   sessionUser.status = 0;
          //   setSessionUser(sessionUser);
          // })
        } else {
          this.$router.back();
        }
      },
      /**
       * 打开协议的页面
       */
      openProtocol() {
        this.step = 0;
      },

      /**
       * 返回网站首页
       */
      toHome(){
		  
		  sessionStorage.setItem("uid",'');
		  sessionStorage.setItem("uname", '');
        this.$router.replace({"name": "网站首页"})
      }

    }
  }
</script>

<style scoped>

  .status{
    width: 100%;
    min-height: 700px;
    background: #fff;
    text-align: center;
  }

  .status .el-image{
    width: 90%;
    display: block;
    margin: auto;
  }

  .status .el-image.error{
    width: 600px;
    padding-top: 50px;
    /*height: 500px;*/
  }

  .status .tips{
    color: #000;
    opacity: 0.5;
  }

  .status .el-button{
    min-width: 200px;
  }

  .wrap {
    width: 1200px;
    margin: 20px auto;
  }

  .content {
    background: #FFFFFF;
    padding: 20px 90px 50px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    /*display: inline-block;*/
    /*transform: translateX(-12%);*/
    line-height: 50px;
    color: #ffffff;
    font-size: 13px;
  }

  .description {
    margin-bottom: 40px;
  }

  .tips {
    color: #DA575D;
    font-size: 13px;
  }

  p {
    margin-bottom: 0px;
  }

  .el-checkbox.is-bordered {
    padding-left: 0px !important;
    border: unset;
  }

  .el-button--text {
    color: #3BD1DC !important;
  }

  .el-row.step {
    height: 50px;
    margin-bottom: 10px;
  }

  .step .el-col {
    background-image: url("../../assets/images/bg-step.png");
    height: 100%;
    background-size: 100% 100%;
  }

  .step .el-col:first-child {
    background-image: url("../../assets/images/bg-step01.png");
  }

  .step .bg-white {
    background-image: url("../../assets/images/bg-step02.png");

  }

  .step .bg-white .title {
    color: #000;
  }

  .el-form {
    padding: 0px 250px;
  }

  .el-form .el-radio-group {
    width: 100%;
  }

  .el-form .btn-next {
    width: 50%;
  }

  .el-form .captcha .text-input {
    padding-left: 0px !important;
  }

  .el-form .captcha .text-captcha {
    height: 35px;
  }

  .el-form .captcha .text-captcha img {
    margin-top: 2px;
    cursor: pointer;
  }

  .el-form .el-radio-group {
    text-align: center;
  }

  .el-form .addr .el-col {
    padding: 0px 2px 0px 0px;
  }

  .el-form .addr .el-col:last-child {
    padding: 0px;
  }

  .el-row.idcard .el-col .el-image {
    width: 100%;
    min-height: 80px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .el-row.idcard .el-col {
    padding: 0px 5px;
  }

  .el-row.idcard .el-col .desc {
    font-size: 12px;
    color: #cccccc;
    text-align: center;
    margin-top: -10px;
  }

  .idcard-tips {
    font-size: 12px;
    color: #F25A60;
  }
</style>
