<template>
	<el-dialog title="资料上传" :visible.sync="dialogVisible" width="612px" style="margin-top: 1vh">
		<Pay ref="pay" :message1="checkedGift" />

		<div class="info">
			
			
			<div>教材名称：
				<el-input type="text" :rows="2" placeholder="" v-model="s_jname" style="margin-top: 10px" />
			</div>
			<div>第几版本：
				<el-input type="text" :rows="2" placeholder="" v-model="s_banben" style="margin-top: 10px" />
			</div>
			<div>封面标志性说明，适用人员，适用范围：
				<el-input type="text" :rows="2" placeholder="" v-model="s_shiyongren" style="margin-top: 10px" />
			</div>
			<div>题目、章节名称：
				<el-input type="text" :rows="2" placeholder="" v-model="s_timu" style="margin-top: 10px" />
			</div>
			<div>主编:
				<el-input type="text" :rows="2" placeholder="" v-model="s_zhubian" style="margin-top: 10px" />
			</div>
			<div>副主编:
				<el-input type="text" :rows="2" placeholder="" v-model="s_fuzubain" style="margin-top: 10px" />
			</div>
			<div>出版发行(社):
				<el-input type="text" :rows="2" placeholder="" v-model="s_chubanshe" style="margin-top: 10px" />
			</div>
			<div>出版日期:
			<el-date-picker v-model="s_chubantime"
			                value-format="yyyy-MM-dd" 
							
							 style="margin-top: 10px"
			              >
			</el-date-picker>
			            
			</div>
			<div>图片制作上传者姓名:
				<el-input type="text" :rows="2" placeholder="" v-model="s_imgname" style="margin-top: 10px" />
			</div> 
			<div>图片制作上传者所属单位（首选）或曾履职单位，学习、毕业学校:
				<el-input type="text" :rows="2" placeholder="" v-model="s_danwei" style="margin-top: 10px" />
			</div> 
			
			
			<hr/>

			<div>资料标题：
				<el-input type="text" :rows="2" placeholder="输入资料标题" v-model="title" style="margin-top: 10px" />
			</div>
			<div> 预期价格：
				<el-input type="text" :rows="2" placeholder="输入资料内容" v-model="price" style="margin-top: 10px" />
			</div>
			<div> 简介内容(限100字)：
				<el-input type="textarea" :rows="2" placeholder="输入资料内容" v-model="simple" style="margin-top: 10px" />
			</div> 

			<div> 资料内容(限2000字)：
				<el-input type="textarea" :rows="2" placeholder="输入资料内容" v-model="textarea" style="margin-top: 10px" />
			</div>
			
			

			<div> 上传视频：


				<div class="album albumvideo">
					<div>
						<p class="type_title">
							<span>视频介绍</span>
						</p>
						<div class="pic_img">
							<div class="pic_img_box">
								<el-upload class="avatar-uploader" action :http-request="uploadFile"
									v-bind:data="{FoldPath:'上传目录',SecretKey:'安全验证'}"
									v-bind:on-progress="uploadVideoProcess" v-bind:on-success="handleVideoSuccess"
									v-bind:before-upload="beforeUploadVideo" v-bind:show-file-list="false">
									<video v-if="videoForm.showVideoPath !='' && !videoFlag"
										v-bind:src="videoForm.showVideoPath" class="avatar video-avatar"
										controls="controls">
										您的浏览器不支持视频播放
									</video>
									<i v-else-if="videoForm.showVideoPath =='' && !videoFlag"
										class="el-icon-plus avatar-uploader-icon"></i>
									<el-progress v-if="videoFlag == true" type="circle"
										v-bind:percentage="videoUploadPercent" style="margin-top:7px;"></el-progress>
								</el-upload>
							</div>
						</div>
					</div>
					<p class="Upload_pictures">
						<span></span>
						<span>最多可以上传1个视频，建议大小50M，推荐格式mp4</span>
					</p>
				</div>




			</div>



		</div>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="handleSend3(1)">提交</el-button>
		</span>
	</el-dialog>


</template>

<script>
	import axios from "axios";
	import data from '@/data/data.js'
	import Pay from './Pay.vue'
	export default {
		name: "MyUpdata",
		components: {
			Pay
		},
		props: ['info'],
		data() {
			return {
				s_jname:'',
				s_banben:'',
				s_shiyongren:'',
				s_timu:'',
				s_zhubian:'',
				s_fuzubain:'',
				s_chubanshe:'',
				s_chubantime:'',
				s_imgname:'',
				s_danwei:'',
				
				
				type:1,
				//---------------
				videoFlag: false,
				//是否显示进度条
				videoUploadPercent: "",
				//进度条的进度，
				isShowUploadVideo: false,
				//显示上传按钮
				videoForm: {
					showVideoPath: ''
				}, 
				video_s: '',  
				//----------------



				messageToHelloWorld: '我是父组件向下传递的信息',
				id: 0,
				dialogVisible: false,
				gifts: [],
				index: 0,
				checkedGift: {
					id: 60,
					uid: 60,
					name: '玫瑰花',
					url: require('../assets/gifts/5.jpg'),
					bid: 0,
					num: 0,
					message: '',

				},
				finfo: {},
				num: 1,
				title: '',
				textarea: '',
				simple:'',
				price:'',
			};
		},
		created() {
			const params1 = new URLSearchParams();
			//params.append('uid', sessionStorage.getItem('uid')); 

			axios.post(this.apiUrl + '/api/user/getGift', params1)
				.then((response) => {

					this.gifts = response.data.data;

				})
				.catch(function(error) {
					console.log(error);
				});

		},

		watch: {
			info: function(newVal, oldVal) {
				this.finfo = newVal; //newVal即是chartData
				this.drawChart();
			}
		},
		mounted() {


			//this.formatGifts(data.gifts)

			//礼物列表




		},
		methods: {
			 
			//上传文件的事件
			uploadFile(item) {
				// this.$showMessage('文件上传中........')
				//上传文件的需要formdata类型;所以要转
				let FormDatas = new FormData()
				FormDatas.append('video_s', item.file);

				axios.post(this.apiUrl + '/api/user/upload_video', FormDatas)
					.then((response) => {
						//alert(333)
						// this.gifts =  response.data.data;
						// console.log("22222222")
						// console.log(response)
						// console.log("22222222")
						// alert(response.data.data)

						if (response.data.code == 0) {
							this.videoForm.showVideoPath = response.data.data;

						}



					})
					.catch(function(error) {
						console.log(error);
					});



				// this.$axios({
				// 	method: 'post',
				// 	url: this.apiUrl+'/api/user/upload_video',
				// 	//headers:this.headers,s
				// 	//timeout: 30000,
				// 	data: FormDatas
				// 	}).then(res=>{
				// 		if(res.data.id != '' || res.data.id != null){
				// 			this.fileList.push(item.file);//成功过后手动将文件添加到展示列表里
				// 			let i = this.fileList.indexOf(item.file)
				// 			this.fileList[i].id = res.data.id;//id也添加进去，最后整个大表单提交的时候需要的
				// 			if(this.fileList.length > 0){//如果上传了附件就把校验规则给干掉
				// 				this.fileflag = false;
				// 				this.$set(this.rules.url,0,'')
				// 			}
				// 			//this.handleSuccess();
				// 		}
				// 	})
			},


			//----------------
			//上传前回调

			beforeUploadVideo(file) {
				var fileSize = file.size / 1024 / 1024 < 50;
				if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(
						file.type) == -1) {
					layer.msg("请上传正确的视频格式");
					return false;
				}
				if (!fileSize) {
					layer.msg("视频大小不能超过50MB");
					return false;
				}
				this.isShowUploadVideo = false;
			},
			//进度条
			uploadVideoProcess(event, file, fileList) {
				this.videoFlag = true;
				this.videoUploadPercent = file.percentage.toFixed(0) * 1;
			},
			//上传成功回调
			handleVideoSuccess(res, file) {

				this.isShowUploadVideo = true;
				this.videoFlag = false;
				this.videoUploadPercent = 0;

				//前台上传地址
				if (file.status == 'success') {
					alert(file.url)
					this.videoForm.showVideoPath = file.url;
				} else {
					layer.msg("上传失败，请重新上传");
				}

				//后台上传地址
				if (res.Code == 0) {
					this.videoForm.showVideoPath = res.Data;
				} else {
					layer.msg(res.Message);
				}
			},

			//----------------


			gohandleSend2() {
				//alert(3);
			},
			open(e) { 
				
				this.type = e
				this.dialogVisible = true
			
			},
			handleSend3(e) {
				 


				const params3 = new URLSearchParams();
				
				//------------检索条件------------ 
				
				params3.append('s_jname', this.s_jname);
				params3.append('s_banben', this.s_banben);
				params3.append('s_shiyongren', this.s_shiyongren);
				params3.append('s_timu', this.s_timu);
				params3.append('s_zhubian', this.s_zhubian);
				params3.append('s_fuzubain', this.s_fuzubain);
				params3.append('s_chubanshe', this.s_chubanshe);
				params3.append('s_chubantime', this.s_chubantime);
				params3.append('s_imgname', this.s_imgname);
				params3.append('s_danwei', this.s_danwei); 
				
				
				//------------检索条件------------

				params3.append('u_id', sessionStorage.getItem('uid'));
				params3.append('title', this.title);
				params3.append('content', this.textarea);
				params3.append('type', this.type);
				params3.append('video_s', this.videoForm.showVideoPath); 
				
				
				params3.append('simple', this.simple); 
				params3.append('price', this.price);   


				// $data['u_id'] = $_POST['u_id'];
				// $data['title'] = $_POST['title'];
				// $data['content'] = $_POST['content'];
				// $data['type'] = $_POST['type'];
				// $data['video_s'] = $_POST['video_s']; 	  

				axios.post(this.apiUrl + '/api/user/addUpdateDatas', params3)
					.then((response) => {
						//this.$message.alert('操作成功')  
						this.videoFlag = false
					})
					.catch(function(error) {
						console.log(error);
					});




				// 	   this.checkedGift.uid = sessionStorage.getItem('uid');
				// 	   this.checkedGift.bid = this.finfo.id;
				// 	   this.checkedGift.num = this.num;
				// 	   this.checkedGift.message = this.textarea; 



				// 	   this.$refs.pay.open()
			},
			handleSend2() {
				const that = this
				this.$confirm('确认赠送？')
					.then(() => {
						console.log("++++++++")
						console.log(this.checkedGift)


						const params3 = new URLSearchParams();

						params3.append('uid', sessionStorage.getItem('uid'));

						params3.append('bid', this.id);

						params3.append('name', this.checkedGift.name);

						params3.append('url', this.checkedGift.url);

						params3.append('num', this.num);

						params3.append('message', this.textarea);



						axios.post(this.apiUrl + '/api/user/giveGift', params3)
							.then((response) => {

								this.gifts = response.data.data;

							})
							.catch(function(error) {
								console.log(error);
							});



					})
					.catch(() => {});
			},
			handleSend() {
				const that = this
				this.$confirm('确认赠送？')
					.then(() => {
						that.dialogVisible = false
					})
					.catch(() => {});
			},
			formatGifts(data) {
				let num = Math.ceil(data.length / 12)
				this.gifts = new Array(num)
				for (let i = 0; i < num; i++) {
					this.gifts[i] = data.slice(i * 12, i * 12 + 12);
				}
			},
			check(item) {
				this.checkedGift = item
			}
		}
	}
</script>

<style scoped lang="scss">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.video-avatar {
		width: 400px;
		height: 200px;
	}



	.gifts {
		.title {
			margin-bottom: 21px;
			color: #333;
		}

		.gift {
			width: 84px;
			height: 84px;
		}

		.el-icon-success {
			position: absolute;
			bottom: 5px;
			right: 50%;
			transform: translateX(42px);
			font-size: 17px;
			color: #FF2D2D;
		}

		.el-pagination {
			text-align: center;
		}
	}

	.info {
		&>div {
			margin-bottom: 10px;
		}
	}

	/deep/ .el-dialog__header {
		background-color: $green;
		height: 33px;
		padding: 0 0 0 27px;

		.el-dialog__title {
			line-height: 33px;
			font-size: 12px;
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 8px;

			.el-dialog__close {
				color: #fff;
			}
		}
	}

	/deep/ .el-dialog__body {
		padding: 18px 16px;
	}

	/deep/ .el-dialog__footer {
		text-align: center;

		.el-button--primary {
			width: 95px;
			height: 32px;
			background-color: $green;
			border-color: $green;
		}
	}

	/deep/ .el-pager li:last-child {
		border-color: $green
	}

	.el-col-4 {
		text-align: center;
		position: relative;
		margin-bottom: 16px;
	}

	.el-input-number--mini {
		width: 74px;
		line-height: 22px;
	}

	/*/deep/ .el-input__inner{*/
	/*  border: unset;*/
	/*}*/
	.el-input-number__decrease,
	.el-input-number__increase {
		background-color: transparent;
	}

	/deep/ .el-input--mini .el-input__inner {
		padding-left: 0;
		padding-right: 0;
	}

	/deep/ .el-input--mini .el-input__inner {
		height: 24px;
	}

	/deep/ .el-input-number--mini .el-input-number__decrease,
	/deep/ .el-input-number--mini .el-input-number__increase {
		width: 20px;
	}
</style>
